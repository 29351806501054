.footerSection{
    background: linear-gradient(135deg, #052247 0%, #9055A2 83.04%, #FF4A1C 100%);
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    padding-bottom: 1rem;
    
    
}
.secondContainer{
    justify-content: space-around;
    width: 80%;
    flex-wrap: wrap;
    align-items: flex-start;
}



  .copyRight{
    color: white;
  }
  @media (max-width:767px) {
    .leftPane, .middlePane, .rightPane{
      width: 100% !important;
    }
  }
  @media (max-width:1370px) {
    .secondContainer{
      width: 86% ;
    }
  }
  @media (min-width: 2000px) {
    .secondContainer{
        max-width: 1400px;
        width: 60%;
    }
  }
  .leftPane, .middlePane, .rightPane{
    width: 30%;
  }
  .leftPane{
    margin-top: 80px;
  }