.ThankyouRoot{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
    position: fixed;
    top: 0;
    .alignment{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h3{
            font-weight: 500 !important;
            text-align: center;
            font-size: 20px;
            margin-bottom: 20px;
            padding: 5%;
        }
        button{
            background-color: #FF4A1C;
        }
    }
}