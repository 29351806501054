.homeSection {
  display: flex;
  flex-direction: row;
  // height: 100%;
  // max-height: 1000px;
  justify-content: center;
  // align-items: flex-end;
}
.secondContainer {
  // height: 100%;
  max-width: 1400px;
  width: 76%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.rightBgImage {
  width: 1058px;
  // height: 144px;
  top: 20px;
  left: -27px;
  position: absolute;
}

.leftpane {
  width: 40%;
  height: fit-content;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  // height: 100%;
  position: relative;
  // padding-top: 20vh;
}

.rightPane {
  width: 60%;
  text-align: left;
  // height: 100%;

  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.rightText {
  position: relative;
  margin-top: 12vh;
}
.credvest {
  color: #fff;
  font-family: Oxygen;
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
  letter-spacing: 0.284px;
  text-transform: uppercase;
  text-align: right;
}

.knowGood {
  color: #fff;
  font-family: Meddon;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: 0.166px;
  text-transform: capitalize;
  text-align: right;
}

.header1 {
  color: #071013;
  font-family: Oxygen;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.083px;
  text-transform: capitalize;
  padding-bottom: 0.5rem;
}

.header2 {
  color: #ff4a1c;
  font-family: Oxygen;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.029px;
  padding-bottom: 0.5rem;
}

.description {
  color: #071013;
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: 0.075px;
  margin-bottom: 16px;
}

.buttonText {
  color: white;
  font-weight: 600;
  font-size: 0.875rem;
  @media (min-width: 640px) {
    font-size: 1rem;
  }
}

.icon {
  width: 1.2rem;
}
@media (max-width: 1388px) {
  .header1 {
    font-size: 1.8rem;
  }
  .header2 {
    font-size: 1.1rem;
  }
  .description {
    font-size: 0.9;
  }
}
@media (max-width: 1364px) {
  .credvest {
    font-size: 6rem;
  }
  .knowGood {
    font-size: 3.6rem;
  }
}
@media (max-width: 1300px) {
  .secondContainer {
    width: 86%;
  }
}
@media (max-width: 1205px) {
  .credvest {
    font-size: 5rem;
  }
  .knowGood {
    font-size: 3.2rem;
  }
  .header1 {
    font-size: 1.6rem;
  }
}
@media (max-width: 1057px) {
  .credvest {
    font-size: 4rem;
  }
  .knowGood {
    font-size: 2.6rem;
  }
  .header1 {
    font-size: 1.8rem;
  }
  .header2 {
    font-size: 1rem;
  }
  .description {
    font-size: 0.9rem;
  }
}
@media (max-width: 912px) {
  .homeSection {
    background-position: -225px -326px, center top;
    background-size: 859px, 1300px;
    // height: 900px;
  }

  .secondContainer {
    width: 86%;
  }
  .rightPane {
    position: absolute;
    width: 100%;
    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
  .leftpane {
    width: 100%;
    z-index: 1;
  }
  .rightBgImage {
    position: absolute;
    top: 9%;
    width: 1085px;
    left: 53%;
    transform: translate(-50%, -50%);
  }
  .rightText {
    width: 500px;
    position: absolute;
    top: 10%;
    right: 40%;
  }
  .credvest {
    font-size: 1.6rem;
  }
  .knowGood {
    font-size: 1rem;
  }
}

@media (max-width: 425px) {
  .rightBgImage {
    width: 800px;
    top: 9%;
  }
  .homeSection {
    height: 800px;
  }
  .rightText {
    bottom: 100px;
    right: 34%;
  }
  .header1 {
    font-size: 1.6rem;
  }
}
@media (min-width: 2000px) {
  .homeSection {
    max-height: 1600px;
    background-position: -225px -326px, 28vw 38px;
    background-size: 1030px, 1420px;
  }
  .secondContainer {
    max-width: 1400px;
    width: 60%;
  }
  .header1 {
    font-size: 2.5rem;
  }
  .header2 {
    font-size: 1.8rem;
  }
  .description {
    font-size: 1.2rem;
  }
  .rightBgImage {
    top: 126px;
    width: 1200px;
  }
}
@media (min-width: 1900px) {
  .homeSection {
    background-position: -225px -326px, 30vw 38px;
  }
}
@media (min-width: 2180px) {
  .homeSection {
    background-position: -225px -326px, 70% 38px;
  }
}
@media screen and (min-width: 912px) and (max-height: 700px) {
  .homeSection {
    // min-height: 700px;
  }
}
