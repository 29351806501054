.parentContainer {
  height: 900px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondContainer {
  display: flex;
  width: 76%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  z-index: 4;
}
.absoluteImage {
  position: absolute;
  height: 100%zzzz;
  width: 1000px;
  right: 7%;
  bottom: 24px;
  overflow: visible;
}

.pageTitle {
  color: #071013;
  font-family: Oxygen;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */
  width: 40%;
  margin-bottom: 2%;
}
.textBody {
  color: #071013;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
  letter-spacing: 0.08px;
  width: 40%;
}

@media (max-width: 768px) {
  .absoluteImage {
    width: 1500px;
    right: -140px;
    bottom: 300px;
  }
  .textBody {
    color: white;
    width: 80%;
  }
  .pageTitle {
    color: white;
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .absoluteImage {
    width: 1000px;
    right: -140px;
    bottom: -30px;
  }
}

@media (max-width: 1336px) {
  .absoluteImage {
    right: 0px;
  }
}
@media (max-width: 1300px) {
  .secondContainer {
    width: 86%;
  }
}

@media (max-width: 1164px) {
  .absoluteImage {
    right: -80px;
    width: 900px;
  }
}
@media (max-width: 914px) {
  .absoluteImage {
    top: 50%;
    left: 50%;
    transform: translate(-62%, -114%);
    width: 719px;
  }
  .parentContainer {
    align-items: flex-end;
    padding-bottom: 160px;
  }
  .pageTitle {
    width: 100%;
    color: black;
  }
  .textBody {
    width: 100%;
    color: black;
  }
}
@media (max-width: 500px) {
  .parentContainer {
    align-items: flex-end;
    height: 854px;
  }
  .absoluteImage {
    width: 560px;
    transform: translate(-62%, -107%);
  }
  .textBody {
    width: 100%;
    font-size: 16px;
  }
  .pageTitle {
    width: 100%;
    font-size: 24px;
    margin-bottom: 2rem;
  }

  .secondContainer {
    width: 90%;
  }
  .parentContainer {
    padding-bottom: 80px !important;
  }
}

@media (min-width: 2000px) {
  .secondContainer {
    max-width: 1400px;
    width: 60%;
  }
}
@media (min-width: 2300px) {
  .absoluteImage {
    right: 14%;
  }
}

@media (min-width: 2800px) {
  .absoluteImage {
    right: 18%;
  }
}
@media (min-width: 3000px) {
  .absoluteImage {
    right: 22%;
  }
}
