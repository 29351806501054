.parentContainer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondContainer{
  justify-content: space-around;
  width: 76%;
}

.privacyContainer {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  color: #333; // Neutral dark color for text
  text-align: left;

  h1, h2 {
      padding-bottom: 10px;
      margin-top: 20px;
  }

  h1 {
      font-size: 2em;
  }

  h2 {
      font-size: 1.5em;
  }

  p {
      font-size: 1em;
      line-height: 1.6;
      margin-bottom: 20px;
  }

  ul {
      list-style-type: disc;
      margin-left: 20px;
      margin-bottom: 20px;

      li {
          margin-bottom: 10px;
      }
  }

  a {
      color: #007BFF; // A general blue link color
      text-decoration: none;

      &:hover {
          text-decoration: underline;
      }
  }
}
@media (max-width:1370px) {
  .secondContainer{
    width: 86%;
  }
}
@media (min-width: 2000px) {
  .secondContainer{
      max-width: 1400px;
      width: 60%;
  }
}