.parentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10% 0;

  background-image: url("../../../assets/ContactUs/contactUsBG.png");
  background-repeat: no-repeat, no-repeat;
  background-size: 100% auto;
}
.secondContainer {
  width: 80%;
}

.title {
  color: #071013;
  font-family: Oxygen;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 39.6px */
  letter-spacing: 0.083px;
  margin-bottom: 4%;
}
.EmailInput,
.NameInput,
.PhoneInput {
  border-radius: 16px;
  min-width: 100px;
  width: 100%;
  padding: 16px;
  background: #fbfffe;
  box-shadow: 0px 0px 72px 0px rgba(0, 0, 0, 0.12),
    0px 4px 18px 0px rgba(0, 0, 0, 0.08);
  flex: 1;
}
.fields {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 4%;
}
.lastRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 80px;
}
.buttonContainer {
  margin-left: 2%;
}
@media (max-width: 913px) {
  .secondContainer {
    width: 80%;
  }
  .fields {
    flex-direction: column;
  }
  .EmailInput,
  .NameInput,
  .PhoneInput {
    width: 100%;
  }
  .lastRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .secondContainer {
    width: 86%;
  }
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.popupButton {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}
@media (min-width: 2000px) {
  .secondContainer {
    max-width: 1400px;
    width: 80%;
  }
}

.gayColor {
  background: linear-gradient(90deg, #ff4a1c 0%, #ff4a1c 33%, #9055a2 100%);
  background-clip: text;
  color: transparent;
}
