.gradient{
	background: linear-gradient(90deg, #FF4A1C 27.99%, #FF7C5A 59.62%);
	background-clip:text;
	color:transparent;
}                
.gayColor {
			background: linear-gradient(90.31deg, #FF4A1C 47%, #9055A2 94.07%);
		background-clip: text;
		color: transparent;
}
.startFinish{
		background: linear-gradient(90deg, #F54B28 0%, #9055A2 100%);
		background-clip: text;
		color: transparent;
}


