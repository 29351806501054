@font-face {
    font-family: 'Oxygen';
    src: url('../public/fonts/Oxygen-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Oxygen';
    src: url('../public/fonts/Oxygen-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Oxygen';
    src: url('../public/fonts/Oxygen-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Meddon';
    src: url('../public/fonts/Meddon-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  
  .oxygen{
    font-family: 'Oxygen', sans-serif;
  }
  .meddon{
    font-family: 'Meddon', sans-serif;

  }

  .button{
    background-color: #FF4A1C;
    padding: 8px 16px;
    border-radius: 16px;
    display: flex;
    gap: 5px;
    align-items: center;
    color: white;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    width: fit-content;
    
  &:hover {
    // styles when the icon is hovered (e.g., scale, color change, etc.)
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }

    .buttonText{
      color: #FFF;
text-align: center;
font-family: Source Sans Pro;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 27.55px */
letter-spacing: 0.095px;
    }
}

/* animations.css */
