.parentContainer {
  height: 900px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondContainer {
  display: flex;
  position: relative;
  width: 76%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  z-index: 4;
}
.absoluteImage {
  position: absolute;
  height: 100%;
  width: 2000px;
  right: 20px;
  bottom: 227px;
  overflow: visible;
}

.pageTitle {
  color: #071013;
  font-family: Oxygen;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */
  width: 40%;
  margin-bottom: 2%;
}
.textBody {
  color: #071013;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
  letter-spacing: 0.08px;
  width: 40%;
}

@media screen and (max-width: 768px) {
  .absoluteImage {
    width: 1500px;
    right: -140px;
    bottom: 300px;
  }
  .textBody {
    color: rgb(0, 0, 0);
    width: 80%;
  }
  .pageTitle {
    color: rgb(0, 0, 0);
    width: 80%;
  }
}

@media screen and (max-width: 1024px) {
  .absoluteImage {
    width: 2000px;
    right: -140px;
  }
}

@media screen and (max-width: 913px) {
  .parentContainer {
    height: auto;
  }
  .absoluteImage {
    width: 1474px;
    top: 50%;
    left: 50%;
    transform: translate(-77%, -69%);
  }
  .textBody {
    color: rgb(0, 0, 0);
    width: 80%;
    font-size: 20px;
  }
  .pageTitle {
    color: rgb(0, 0, 0);
    width: 80%;
    font-size: 30px;
  }
  .secondContainer {
    justify-content: flex-end;
    padding-bottom: 143px;
    height: 1024px;
  }
  .textBody {
    width: 100%;
  }
  .secondContainer {
    width: 86%;
  }
}
@media screen and (max-width: 500px) {
  .textBody {
    width: 100%;
    font-size: 16px;
  }
  .pageTitle {
    width: 100%;
    font-size: 24px;
    margin-bottom: 2rem;
  }
  .secondContainer {
    width: 90%;
    padding-bottom: 100px;
  }
  .absoluteImage {
    transform: translate(-78%, -60%);
    width: 1010px;
  }
  .secondContainer {
    height: 846px;
  }
}
@media (max-width: 1340px) {
  .absoluteImage {
    right: -66px;
  }
}
@media (max-width: 1172px) {
  .absoluteImage {
    right: -140px;
  }
}
@media (max-width: 1069px) {
  .absoluteImage {
    right: -187px;
  }
}
@media (max-width: 998px) {
  .absoluteImage {
    right: -238px;
  }
}

@media (min-width: 2000px) {
  .secondContainer {
    max-width: 1400px;
    width: 60%;
  }
}

@media (min-width: 2300px) {
  .absoluteImage {
    right: 14%;
  }
}

@media (min-width: 2800px) {
  .absoluteImage {
    right: 18%;
  }
}
@media (min-width: 3000px) {
  .absoluteImage {
    right: 22%;
  }
}
