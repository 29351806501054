.parentContainer {
  background-image: url("../../../assets/knowGood/KnowGoodSec5bg.webp");
  background-size: cover;
  display: flex;
  height: 100%;
  padding: 10rem 0;
  align-items: center;
  justify-content: center;
}

.secondContainer {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.text {
  width: 70%;
  color: white;
  text-align: center;
  font-family: Oxygen;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
  letter-spacing: 0.048px;
  z-index: 1;
}

.circle {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 1s ease;

  &:hover {
    box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.3);
    transform: scale(1.05);

    .arrow {
      display: block;
      z-index: 10;


    }
  }


}


.arrow {
  display: none; // Hide by default
}

.circle:nth-child(1)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%; // To maintain the circular shape
    background: linear-gradient(180deg, #FF4A1C 0%, #FF6842 100%);
    mix-blend-mode: multiply;
}

.circle:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%; // To maintain the circular shape
  background: linear-gradient(180deg, #9055A2 0%, #A271B1 100%);
  mix-blend-mode: multiply;
}

.circle:nth-child(3)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%; // To maintain the circular shape
  background: linear-gradient(180deg, #052247 0%, #2F4766 100%);
  mix-blend-mode: multiply;
}
@media (max-width:1116px) {
  .secondContainer{
    width: 86%;
    flex-direction: column;
  }
}

@media (min-width: 2000px) {
  .secondContainer{
    max-width: 1400px;
    width: 60%;

  }
}