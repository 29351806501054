.parentContainer{
    height: 100% !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: white;
    padding: 10% 0;
}
.secondContainer{
    width: 76%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 70px;

}
.imageContainer{
    background-image: url('../../../assets/image/blog1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    padding: 2rem;
    position: relative;

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;

    width: 100%;
    height: 700px;

    margin-bottom: 3%;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 20px;
    }
}
.imageTitle{
    color: white;
font-family: Oxygen;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 33.6px */
z-index: 2;
}
.imageCaption{
    color: white;
font-family: Source Sans Pro;
font-size: 18px;
font-style: italic;
font-weight: 400;
line-height: 145%; /* 23.2px */
letter-spacing: 0.08px;
z-index: 2;
}
.textContainer{
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5%;
    justify-content: center;
}
.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 4%;
}

.dateFeature, .longText{
    text-align: left;
    width: 100%;
}
.longText{
    color: #071013;
font-family: Source Sans Pro;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 23.2px */
letter-spacing: 0.08px;
}
.readmoreButton{
    float: right;
    text-align: right;
    width: 100%;
}
.dateFeature{
    color: #3D4446;
font-family: Source Sans Pro;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 145%; /* 18.85px */
letter-spacing: 0.065px;
text-transform: uppercase;
margin-bottom: 1%;
}
.readMoreText{
    color: #FF4A1C;
text-align: center;
font-family: Source Sans Pro;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 145%; /* 23.2px */
letter-spacing: 0.08px;
cursor: pointer;
}
.readmoreButton{
    color: #FF4A1C;
    margin-top: 1%;
}
@media (max-width:500px) {
    .imageContainer{
        height: 300px;
    }
}
@media (min-width: 2000px) {
    .secondContainer{
      max-width: 1400px;
      width: 60%;
  
    }
  }
  .ComingSoon{
    font-size: 2rem;
  }