.homeSection {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-size: cover;
}
.secondContainer {
  // margin-top: 74px;
  width: 76%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
}
.paneContainer {
  width: 100%;
  display: flex;
  align-items: center;
}
.left {
  height: auto;
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.right {
  width: 60%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
}
.title {
  text-align: center;
  font-family: Oxygen;
  font-size: 3rem;
  color: white;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.72px;
}
.heading {
  color: #071013;
  font-family: Oxygen;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: left;
}
.DirectorCuts {
  width: 100%;
}

.iframe {
  width: 100%;
  border: none;
}

.bodyText {
  color: #071013;
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: 0.08px;
  text-align: left;
}
.profileContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}
.profileTextContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.profileName {
  text-align: left;
  color: #071013;
  font-family: Source Sans Pro;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 27.55px */
  letter-spacing: 0.095px;
}
.role {
  text-align: left;
  color: #071013;
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: 0.08px;
}
.imageContainer {
  position: absolute;
  height: 100%;
  width: 737px;
  top: -267px;
}

.profileImage {
  width: 60px;
}

.left1 {
  position: relative;
  mask-size: contain;
  mask-position: center center;
  mask-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to right,
      rgba(144, 85, 162, 0.5),
      rgba(255, 74, 28, 0.5)
    );
  }
}

.customBullet {
  list-style-image: url("../../../assets/home/bulletPoint.svg");
  padding-left: 1rem;
}
.blobMask {
  mask: url("../../../assets/home/blobMask.svg");
  -webkit-mask: url("../../../assets/home/blobMask.svg");
  mask-mode: alpha;
  -webkit-mask-mode: alpha;
  mask-size: 100% 100%;
}
.homeSection {
  background-color: #f9f9f9;
}

@media (max-width: 1300px) {
  .secondContainer {
    width: 89%;
  }
}
@media (max-width: 1089px) {
  .imageContainer {
    width: 585px;
    right: -81px;
  }
  .homeSection {
    background-position: 200px center;
  }
  .homeSection {
    background-size: cover;
  }
}
@media (max-width: 913px) {
  .left {
    width: 100%;
    z-index: 10;
    height: 80%;
    justify-content: flex-end;
  }
  .right {
    position: absolute;
    width: 100%;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .paneContainer {
    height: 900px;
  }
  .heading {
    color: black;
  }
  .bodyText {
    color: black;
  }
  .profileName {
    color: black;
  }
  .role {
    color: black;
  }
  .imageContainer {
    top: 66% !important;
    left: 50%;
    width: 500px;
    transform: translate(-50%, -50%);
  }
  .homeSection {
    position: relative;
    // height: 1150px;
  }
}
@media (max-width: 500px) {
  .left {
    height: 80%;
  }
  .heading {
    font-size: 1.4rem;
  }
  .title {
    font-size: 2.4rem;
  }
  .imageContainer {
    width: 344px;
  }
  .paneContainer {
    height: 800px;
  }
  .homeSection {
    // height: 1031px;
  }
  .profileName {
    font-size: 1rem;
  }
  .role {
    font-size: 1rem;
  }
  .profileContainer {
    gap: 4px;
  }
}
@media (min-width: 2000px) {
  .secondContainer {
    max-width: 1400px;
    width: 60%;
  }
}
