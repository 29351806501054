.parentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh + 100px);
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
  
    // background-color: #f0f0f0;
  }
  .parentContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000061b5;
    z-index: 1; // Assuming video has no z-index. If it does, set this value accordingly.
    background-blend-mode: soft-light, normal;
  }



  
  .backgroundVideo {
    position: absolute;
    object-fit: cover;
  
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
  }
  .secondContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      gap: 130px;
      z-index: 6;
      width: 76%;
  }

  
  .customBullet {
    list-style-image: url("../../../assets/home/bulletPoint.svg");
    padding-left: 1rem;
    color: white;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
  letter-spacing: 0.08px;
  text-align: left;
  }
  .paneContainer{
    width: 100%;
    display: flex;
    align-items: center;flex-direction: row;

  }
  .leftTabs{
    color: #FFF;
    font-family: Oxygen;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 145%; /* 27.55px */
    text-align: left;
    padding: 30px 32px;
    cursor: pointer;
  
    &:hover {
      background: linear-gradient(90deg, #360177 0%, rgba(255, 74, 28, 0.00) 100%);
    }
  
    &.activeTab { // Here's the change
      background: linear-gradient(90deg, #FF4A1C 0%, rgba(255, 74, 28, 0.00) 100%);
      color: #FFF; // Making the text white
    }
  }
  .left{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #ff4a1c;

  }
  .right{
    width: 70%;
  }
  .titleText{
    color: white;
    text-align: left;
  }

  .key {
    background: linear-gradient(90deg, #ff4a1c 0%, #9055a2 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .key,
  .pageTitle {
    font-family: Oxygen;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.72px;
  }
  .pageTitle{
      color: white;
  }

  @media (max-width:913px) {
    .parentContainer{
      height: 1200px;

    }
    .paneContainer{
      flex-direction: column-reverse;
      gap: 60px;
      justify-content: space-between;
    }
    .left{
      width: 100%;
    }
    .right{
      width: 100%;
    }
  }
  @media (min-width: 2000px) {
    .secondContainer{
      max-width: 1400px;
      width: 60%;
  
    }
  }