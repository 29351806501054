.parentContainer {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10% 0;
}
.secondContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 76%;
  justify-content: center;
  align-items: flex-start;
}
.imageContainer {
  width: 550px;
  // height: auto;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}
.cultureIcon,
.openingsIcon,
.peopleIcon {
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
  }
}
.leftContainer {
  position: relative;
  height: auto;
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: center;
}
.rightContainer {
  height: 380px;
  margin-top: 4%;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.cultureIcon {
  top: 18%;
  left: 62%;
}
.openingsIcon {
  top: 43%;
  left: 74%;
}
.peopleIcon {
  top: 67%;
  left: 65%;
}
.cultureIcon,
.openingsIcon,
.peopleIcon {
  position: absolute;
  background-color: #dedfdf;
  border-radius: 50%; /* This makes the image circular */
  width: 90px; /* Width of the image */
  height: 90px; /* Height of the image */
  display: block; /* This ensures the border-radius is applied properly */
  object-fit: cover; /* This ensures the image covers the entire circle */
}
.card {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: auto;
  width: 100%;
  gap: 8px;
  padding-bottom: 30px !important;
  position: relative;
  padding: 30px;
  border-radius: 32px;
  background: #fff;
  box-shadow: 0px 0px 72px 0px rgba(0, 0, 0, 0.12),
    0px 4px 18px 0px rgba(0, 0, 0, 0.08);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit; /* !importanté */
    background: var(--dynamic-gradient);
  }
}
.gradientBorder {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button {
  display: flex;
  align-items: center; // vertically center align items
  gap: 5px;
  justify-content: center; // horizontally center align items

  // Other button styles ...

  .faCoffee {
    // or whatever class FontAwesome assigns to the icon
    margin-right: 10px; // Add some spacing between icon and text
  }
}

.rightTitle {
  color: #071013;
  font-family: Oxygen;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */
  text-align: left;
}
.rightTextBody {
  color: #071013;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
  letter-spacing: 0.08px;
  text-align: left;
  padding-bottom: 4%;
}
// ... other styles ...

.cultureIcon,
.openingsIcon,
.peopleIcon {
  transition: all 0.3s ease-in-out;

  &:hover {
    // styles when the icon is hovered (e.g., scale, color change, etc.)
    transform: scale(1.1);
  }
}

.cultureActive {
  stroke: #052247;
  border: 3px solid #052247;
  background: white;
}

.openingsActive {
  stroke: #8d539e;
  fill: #8d539e;
  background: white;

  border: 3px solid #8d539e;
}

.peopleActive {
  stroke: #fa4b1d;
  background: white;

  fill: #fa4b1d;
  border: 3px solid #fa4b1d;
}
@media (max-width: 1300px) {
  .secondContainer {
    width: 86%;
  }
}
@media (max-width: 1200px) {
  .cultureIcon,
  .peopleIcon,
  .openingsIcon {
    width: 80px;
    height: 80px;
  }
  .openingsIcon {
    left: 78%;
  }
  .peopleIcon {
    left: 69%;
  }
}
@media (max-width: 913px) {
  .secondContainer {
    width: 86%;
    flex-direction: column;
    gap: 80px;
  }
  .leftContainer,
  .rightContainer {
    width: 100%;
  }
  .cultureIcon {
    top: 89%;
    left: 9%;
  }
  .openingsIcon {
    left: 43%;
    top: 101%;
  }
  .peopleIcon {
    top: 89%;
    left: 78%;
  }
  .cultureIcon,
  .peopleIcon,
  .openingsIcon {
    width: 90px;
    height: 90px;
  }
  .parentContainer {
    background-position: -38vw -230px, 91vw 177px;
  }
}
@media (max-width: 600px) {
  .cultureIcon,
  .openingsIcon,
  .peopleIcon {
    width: 70px;
    height: 70px;
  }
  .rightTitle {
    font-size: 1.4rem;
  }
  .rightTextBody {
    font-size: 1rem;
  }
}
@media (min-width: 2000px) {
  .secondContainer {
    max-width: 1400px;
    width: 60%;
  }
}

@keyframes zoomBounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(
      1.15
    ); // Adjust this value for a more pronounced or subtle effect
  }
}

.cultureIcon,
.openingsIcon,
.peopleIcon {
  cursor: pointer; // To indicate it's clickable
  transition: all 0.3s ease; // For a smooth effect when clicked
  animation: zoomBounce 1.5s infinite; // Apply the zoomBounce animation
}

.cultureIcon:hover,
.openingsIcon:hover,
.peopleIcon:hover {
  transform: scale(1.1); // Slight grow effect on hover
}
