.parentContainer {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8% 0 30% 0;
  background-image: url("../../../assets//culture//section2/left.svg"),
    url("../../../assets//culture//section2/right.svg");
  background-position: -70vw bottom, 80vw bottom;
  background-size: cover, 40%;
  background-repeat: no-repeat;
}

.secondContainer {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.textBody {
  color: #151515;
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 27.55px */
  letter-spacing: 0.095px;
  margin-bottom: 4%;
}
.titleGroup {
  padding-bottom: 10%;
}
.addIcon {
  width: 20px;
}
.buttonIcon {
}
.coloredTitle {
  background: linear-gradient(90deg, #ff4a1c 0%, #9055a2 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.coloredTitle,
.blackTitle {
  font-family: Oxygen;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.72px;
}
.blackTitle {
  color: black;
}
@media (max-width:913px) {
  .secondContainer{
    width: 86%;
  }

}

.flexContainer{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  justify-content: center;
  margin-top: 60px;
}
.circleIcon{
  width: 40px;
  fill: white;

}
.circleText{
  font-size: 1.2rem;
  color: white;
  

}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 170px;
  height: 140px;
  border-radius: 70px;
  padding: 20px;

  background-color: #9055A2;

  &:hover {
    // styles when the icon is hovered (e.g., scale, color change, etc.)
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }
}

.hideButton{
  background-color: transparent;
  color: #ff4a1c;
  border: 1px solid #ff4a1c;
  


}
.hideButton, .button {
  /* ... existing styles ... */
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease-in-out;
  
  /* This will give a slight lift effect to the button when hovered */
  &:hover {
    transform: translateY(-3px);
  }

  /* This will give a pressed effect to the button when active (clicked) */
  &:active {
    transform: translateY(1px);
  }
}

@media (min-width: 2000px) {
  .secondContainer{
    max-width: 1000px;
    width: 40%;

  }
}

.circle {
  opacity: 0;
  transform: translateY(30px); // You can adjust this value for desired effect
  transition: opacity 0.5s, transform 0.5s;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}
